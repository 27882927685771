import { Component } from '@angular/core';
import { NgxLeftSidebarComponent, SlideMenuModule, PublicRoutes } from 'ngx-aim';
import { MenuItem } from 'primeng/api';
import { staticMenuItems } from '../static-menu-items';

@Component({
  selector: 'aim-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  standalone: true,
  imports: [SlideMenuModule]
})
export class LeftSidebarComponent extends NgxLeftSidebarComponent {
  override menuItems: MenuItem[] = [
    {
      label: 'Products',
      items: this.appState.categories,
    },
    ...staticMenuItems,
    {
      label: 'Sign In',
      routerLink: `/${PublicRoutes.SIGN_IN}`,
    }
  ];

  constructor() {
    super();
  }
}
