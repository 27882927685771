
<div class="footer primary-bg-color">
  <div class="footer-links">
    <a class="primary-text" [routerLink]="'/' + routes.PRIVACY_POLICY">Privacy Policy</a>
    <a class="primary-text" [routerLink]="'/' + routes.RETURN_POLICY">Returns Policy</a>
    <a class="primary-text" [routerLink]="'/' + routes.SHIPPING_POLICY">Shipping Policy</a>
  </div>
  <div class="social-icons">
    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
      <i class="pi pi-facebook primary-text"></i>
    </a>
  </div>
  <p class="copy-right primary-text-color">
    &copy; {{currentYear}}, {{businessInfo.name}}
  </p>
  <p><a [href]="adminRoutes.ROOT">Admin</a></p>
</div>
