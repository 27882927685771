import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NgxHeaderAccountComponent } from 'ngx-aim';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'aim-header-account',
  templateUrl: './header-account.component.html',
  styleUrls: ['./header-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonModule],
})
export class HeaderAccountComponent extends NgxHeaderAccountComponent {
  @Output()
  override closeOverlay = new EventEmitter<void>();

  constructor() {
    super();
  }
}
